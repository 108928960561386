import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private oauthService: OAuthService) {}

  isLoggedIn(): boolean {
    return (
      this.oauthService.hasValidIdToken() &&
      this.oauthService.hasValidAccessToken()
    );
  }

  getToken(): string {
    return this.oauthService.getAccessToken();
  }

  renewToken(): void {
    // void this.router.navigateByUrl('/');
    // return this.oauthService.logOut();
  }
}
